<template>
  <a-modal
    v-model:visible="visible"
    centered
    :closable="false"
    title="Switch Organization & Role"
    :footer="null"
    destroyOnClose
  >
    <org-switch-modal-vue
      :organizations="organizationList"
      :roles="rolesOptions"
      :email="email"
      @close="toggleModal"
      @refresh="updateList"
    >
    </org-switch-modal-vue>
  </a-modal>
  <a-modal
    v-model:visible="updateRole"
    centered
    :closable="false"
    title="Update Role"
    :footer="null"
    destroyOnClose
  >
    <update-role
      :roles="rolesOptions"
      :email="roleUpdateUserEmail"
      :currentRole="currentRole"
      @close="handleToggleRoleModal"
      @roleUpdated="roleUpdated"
    >
    </update-role>
  </a-modal>

  <a-modal
    v-model:visible="addOrgModal"
    centered
    :closable="false"
    title="Add Organizations"
    ok-text="Add"
    cancel-text="Cancel"
    @ok="addOrganization"
    @cancel="closeAddOrgModal"
    destroyOnClose
  >
    <a-select
      style="width: 100%"
      v-model:value="selectedOrgs"
      mode="multiple"
      :options="tagOptions"
      :filter-option="filterOption"
    />
  </a-modal>

  <a-row>
    <a-col :span="24">
      <a-button
        class="float-right mb-1 d-flex align-items-center"
        type="primary"
        @click="handleShowForm"
        id="add-user-btn"
      >
        <template #icon><i class="bi bi-person-plus mr-2 mb-1"></i></template>
        Add User
      </a-button>
    </a-col>
    <div>
      <a-modal
        v-model:visible="showForm"
        centered
        :closable="false"
        title="Add user"
        :footer="null"
        id="add-user-modal"
        destroyOnClose
      >
        <add-user-new
          :organizations="organizationList"
          :roles="rolesOptions"
          @addUser="addUser"
          @cancel="handleShowForm"
          :isLoading="isLoading"
        ></add-user-new>
      </a-modal>
    </div>
    <a-col
      style="background-color: white; padding: 20px; margin-top: 10px"
      :span="24"
    >
      <a-space>
        <a-typography-title :level="5">
          Users:
          <a-tag style="margin-top: 5px" color="blue">{{ listLength }}</a-tag>
        </a-typography-title>
        <a-input-group compact>
          <a-input
            v-model:value="searchValue"
            style="width: 200px"
            placeholder="Search User"
          />
          <a-select
            class="ml-2"
            style="width: 150px"
            v-model:value="selectedFilter"
            placeholder="Filter By"
            :options="columnOptions"
          ></a-select>
        </a-input-group>
      </a-space>
    </a-col>

    <a-col :span="24">
      <a-table
        :columns="columns"
        :data-source="filterUser"
        :pagination="pagination"
        bordered
        responsive
        :scroll="{ y: '55vh', x: true }"
        :key="tableKey"
        :loading="loading"
        @change="handleTableChange"
      >
        <template #customFilterIcon="{ filtered }">
          <search-outlined
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
        </template>

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'Organization'">
            <a-tag v-for="tag in record.linked_organizations">{{
              tag.organization
            }}</a-tag>
          </template>
          <template v-if="column.dataIndex === 'original_organization'">
            {{ record.is_org_switch ? record.Organization : '-' }}
          </template>
          <template v-if="column.dataIndex === 'actions'">
            <div class="actions">
              <a-dropdown :trigger="['click']">
                <a-button class="ml-3">
                  <template #icon>
                    <MenuOutlined class="menu-icon" />
                  </template>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="handleAddOrgModal(record)">
                      <UserSwitchOutlined />
                      Add New Org
                    </a-menu-item>
                    <!-- end -->
                    <a-menu-item @click="handleToggleRoleModal(record)">
                      <UsergroupAddOutlined />
                      Switch Role
                    </a-menu-item>
                    <a-menu-item v-if="record.is_org_switch">
                      <a-popconfirm
                        title="Are you sure? you want to switch the user back?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="switchUser(record.email)"
                      >
                        <UserSwitchOutlined />
                        Switch Back
                      </a-popconfirm>
                    </a-menu-item>
                    <a-menu-item @click="verifyUserEmail(record.id)">
                      <SafetyCertificateOutlined />
                      Verify Email
                    </a-menu-item>
                    <a-menu-item @click="resetPassword(record.email)">
                      <RedoOutlined />
                      Reset Password
                    </a-menu-item>
                    <a-menu-item
                      v-if="record.isLock"
                      @click="removeLock(record.id)"
                    >
                      <LockOutlined />
                      Finish Lockout
                    </a-menu-item>
                    <a-popconfirm
                      title="Are you sure? you want to delete this user"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="deleteData(record.email)"
                    >
                      <a-menu-item>
                        <DeleteOutlined />
                        Delete
                      </a-menu-item>
                    </a-popconfirm>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </template>
        </template>
      </a-table>
    </a-col>
  </a-row>
</template>

<script>
import {
  DeleteOutlined,
  LockOutlined,
  MenuOutlined,
  RedoOutlined,
  SafetyCertificateOutlined,
  SearchOutlined,
  UsergroupAddOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons-vue';
import { debounce } from 'lodash';
import { roleLabels, roles } from 'src/config/roles-config';
import httpClient from 'src/service/httpClient';
import AuthServices from 'src/services/auth';
import OrgServices from 'src/services/organization';
import UserServices from 'src/services/user';
import { reactive, ref, toRefs } from 'vue';
import { useToast } from 'vue-toastification';
import { mapActions, mapGetters } from 'vuex';
import AddUserNew from './AddUser.vue';
import OrgSwitchModalVue from './OrgSwitchModal.vue';
import UpdateRole from './UpdateRole.vue';

export default {
  name: 'approvedUser-component',
  components: {
    OrgSwitchModalVue,
    SearchOutlined,
    AddUserNew,
    MenuOutlined,
    UserSwitchOutlined,
    SafetyCertificateOutlined,
    DeleteOutlined,
    RedoOutlined,
    LockOutlined,
    UsergroupAddOutlined,
    UpdateRole,
  },
  setup() {
    const componentKey = ref(0);
    const visible = ref(false);
    const toast = useToast();
    const handleOk = (e) => {
      visible.value = false;
    };
    const state = reactive({
      searchText: '',
      searchedColumn: '',
    });

    const handleReset = (clearFilters) => {
      clearFilters();
      state.searchText = '';
    };

    return {
      componentKey,
      // handleSearch,
      handleReset,
      toast,
      visible,
      handleOk,
      activeKey: ref('1'),
      ...toRefs(state),
    };
  },
  data() {
    return {
      sortingColumns: [
        {
          title: 'First Name',
          dataIndex: 'username',
          key: 'name',
          width: '10%',
          align: 'center',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          width: '15%',
          align: 'center',
        },
        {
          title: 'Role',
          dataIndex: 'role',
          width: '10%',
          align: 'center',
          key: 'role',
          filters: [
            { text: 'Admin', value: 'admin' },
            { text: 'Org Admin', value: 'org_admin' },
            { text: 'Labeler', value: 'labeler' },
            { text: 'Org User', value: 'org_user' },
            { text: 'Analytics User', value: 'analytics_user' },
            { text: 'Workstation User', value: 'workstation_user' },
            { text: 'Doc User', value: 'doc_user' },
            { text: 'Support User', value: 'support_user' },
          ],
          filteredValue: this.selectedRole,
          filterMultiple: false,
          onFilter: (value, record) => {
            this.selectedRole = value;
          },
        },
        {
          title: 'Organization',
          dataIndex: 'Organization',
          width: '15%',
          align: 'center',
        },
      ],
      columns: [
        {
          title: 'First Name',
          dataIndex: 'username',
          key: 'name',
          width: '10%',
          align: 'center',
        },
        {
          title: 'Last Name',
          dataIndex: 'lastname',
          width: '10%',
          align: 'center',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          width: '15%',
          align: 'center',
        },
        {
          title: 'Role',
          dataIndex: 'role',
          width: '10%',
          align: 'center',
          key: 'role',
          filters: [
            { text: 'Default', value: null },
            { text: 'Admin', value: 'admin' },
            { text: 'Org Admin', value: 'org_admin' },
            { text: 'Labeler', value: 'labeler' },
            { text: 'Org User', value: 'org_user' },
            { text: 'Analytics User', value: 'analytics_user' },
            { text: 'Workstation User', value: 'workstation_user' },
            { text: 'Doc User', value: 'doc_user' },
            { text: 'Support User', value: 'support_user' },
          ],
          filteredValue: this.selectedRole,
          filterMultiple: false,
          onFilter: (value, record) => {
            this.selectedRole = value;
          },
        },
        {
          title: 'Organizations',
          dataIndex: 'Organization',
          width: '15%',
          align: 'center',
        },
        {
          title: 'Switched To',
          dataIndex: 'original_organization',
          width: '10%',
          align: 'center',
        },
        {
          title: 'Creation Date',
          dataIndex: 'date_joined',
          width: '10%',
          align: 'center',
          // sorter: {
          //   compare: (a, b) => a.date_joined.localeCompare(b.date_joined),
          //   multiple: true
          // }
        },
        {
          title: 'Account Type',
          dataIndex: 'accountType',
          width: '10%',
          align: 'center',
        },
        {
          title: 'Actions',
          dataIndex: 'actions',
          width: '10%',
          align: 'center',
        },
      ],
      loading: false,
      currentPage: 1,
      selectedUser: null,
      selectedOrgs: [],
      selectedUserOrgs: [],
      columnOptions: [],
      isAdmin: null,
      email: null,
      list: [], // approved users list
      allRoles: {},
      roleList: [],
      definedRoles: [
        { text: 'Admin', value: 'admin' },
        { text: 'Org Admin', value: 'org_admin' },
        { text: 'Labeler', value: 'labeler' },
        { text: 'Org User', value: 'org_user' },
        { text: 'Analytics User', value: 'analytics_user' },
        { text: 'Workstation User', value: 'workstation_user' },
        { text: 'Doc User', value: 'doc_user' },
        { text: 'Support User', value: 'support_user' },
      ],
      userDeleteMsg: '',
      userToDelete: null,
      isComfirm: null,
      showForm: false,
      isLoading: false,
      isFetchingUsers: false,
      newList: [],
      isError: false,
      searchValue: '',
      debouncedSearch: null,

      listLength: 0,
      selectedRole: null,
      tableKey: 0,
      selectedFilter: null,
      lockedUsersList: [],
      updateRole: false,
      addOrgModal: false,
    };
  },
  computed: {
    ...mapGetters(['organizations']),

    pagination() {
      return {
        total: this.listLength,
        current: this.currentPage,
        showSizeChanger: false,
        position: ['bottomCenter'],
      };
    },

    tagOptions() {
      return this.organizations.map((org) => ({
        value: org.Org_name,
        label: org.Org_name,
      }));
    },

    filterUser() {
      this.list.map((user) => {
        user.date_joined = user.date_joined.split('T')[0];
        user['isLock'] = this.isUserLock(user.id);
        user['accountType'] = user.is_retrocausal_account ? 'Retro IAM' : 'SSO';
      });
      return this.list;
    },

    rolesOptions() {
      const options = this.roleList.map((role) => ({
        value: role.id,
        label: role.name,
      }));
      return options;
    },
  },
  watch: {
    async selectedRole(value) {
      await this.getData({
        key: this.selectedFilter || 'username',
        value: this.searchValue,
      });
    },

    organizations(value) {
      if (value.length >= 1) {
        this.organizationList = value.map((el) => ({
          value: el.Org_name,
          label: el.Org_name,
        }));
      }
    },

    list() {
      this.tableKey += 1;
    },

    searchValue(newValue) {
      this.handleSearch();
    },

    selectedFilter(newValue) {
      if (this.searchValue) {
        this.handleSearch();
      }
    },
  },

  async created() {
    this.columnOptions = this.sortingColumns
      .filter(
        (column) =>
          column.dataIndex &&
          column.dataIndex !== 'actions' &&
          column.dataIndex !== 'role'
      )
      .map((column) => ({
        label: column.title,
        value: column.dataIndex,
      }));

    this.setPermission();
    this.getOrganizations();
    await this.fetchUsersAndRoles();
    this.updateList();
    this.getLockedUsersList();
    this.initializeDebouncedSearch();
  },
  mounted() {},
  methods: {
    ...mapActions([
      'getPendingUsersList',
      'getOrganizations',
      'fetchAdminPermissions',
      'forgotPassword',
      'addUserToMultipleOrgs',
      'removeUserFromMultipleOrgs',
    ]),

    handleSearch() {
      this.debouncedSearch(this.searchValue);
    },

    initializeDebouncedSearch() {
      this.debouncedSearch = debounce(async (newValue) => {
        await this.getData({
          key: this.selectedFilter || 'username',
          value: newValue,
        });
      }, 500);
    },

    handleTableChange(pag) {
      const { current } = pag;
      this.currentPage = current;
      this.getData();
    },

    filterOption(input, option) {
      if (option.label.toLowerCase().startsWith(input.toLowerCase())) {
        return option.label.toLowerCase().startsWith(input.toLowerCase());
      }
      return;
    },

    async switchUser(userEmail) {
      const [error, data] = await OrgServices.switchUser(userEmail);
      if (error) {
        return;
      }
      this.toast.success('User switched successfully');
      this.getData();
    },

    async getLockedUsersList() {
      const [error, data] = await UserServices.getLockedUsersList();
      if (error) {
        this.toast.error('Failed to fetch locked users.');
        return;
      }
      this.lockedUsersList = data;
    },

    isUserLock(userId) {
      if (this.lockedUsersList.length > 0) {
        return this.lockedUsersList.includes(userId) ? true : false;
      }
    },

    async removeLock(userId) {
      const [error, data] = await UserServices.removeUserLock(userId);
      if (error) {
        this.toast.error('Failed to remove user lock.');
        return;
      }
      this.toast.success('Lock removed.');
      this.getData();
      this.getLockedUsersList();
    },

    handleToggleRoleModal(record) {
      if (record) {
        this.roleUpdateUserEmail = record.email;
        this.currentRole = record.role;
      }
      this.updateRole = !this.updateRole;
    },

    roleUpdated() {
      this.updateRole = false;
      this.getData();
    },

    async verifyUserEmail(id) {
      const [error, data] = await UserServices.verifyUser(id);
      if (error) {
        this.toast.error(`Failed to verify user's email.`);
        return;
      }
      this.toast.success(`User's email verified.`);
    },

    resetPassword(email) {
      this.forgotPassword({
        email: email,
      });
    },

    async updateList() {
      await this.getData();
      this.filterUser; // Accessing the computed property triggers re-computation
    },

    async fetchUsersAndRoles() {
      try {
        this.isFetchingUsers = true;
        const [roles, users] = await Promise.all([
          this.fetchRoles(),
          this.fetchUsers(),
        ]);

        // ref => this.allRoles => {id:name}
        this.newList = users.map((u) => ({
          ...u,
          role: roleLabels[this.allRoles[u.role]],
          accountType: u.is_retrocausal_account ? 'Retro IAM' : 'SSO',
        }));
      } catch (error) {
        console.log('error in users list:', error);
      } finally {
        this.isFetchingUsers = false;
      }
    },

    async setPermission() {
      if (await this.fetchAdminPermissions()) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    },

    handleChange(selectedkey) {
      this.activeKey = selectedkey;
      if (selectedkey == '1') {
        this.updateList();
      } else {
        this.componentKey += 1;
      }
    },

    async addOrganization() {
      this.orgUserList = [];
      this.newOrguserList = [];

      const deletedOrgs = [];
      const newlyCreatedOrgs = [];

      let userExistingOrgs = this.selectedUserOrgs.map(
        (data) => data.organization
      );

      this.selectedOrgs.forEach((org) => {
        if (!userExistingOrgs.includes(org)) {
          newlyCreatedOrgs.push(org);
        }
      });

      userExistingOrgs.forEach((org, index) => {
        if (!this.selectedOrgs.includes(org)) {
          deletedOrgs.push(this.selectedUserOrgs[index].id);
        }
      });

      const payload = newlyCreatedOrgs.map((org) => ({
        user: this.selectedUser.id,
        organization: org,
      }));

      if (newlyCreatedOrgs.length) {
        await this.addUserToMultipleOrgs(payload);
      }

      let removalIds = deletedOrgs.join(',');
      if (removalIds.length) {
        await this.removeUserFromMultipleOrgs({ ids: removalIds });
      }

      if (newlyCreatedOrgs.length || removalIds.length) {
        await this.getData({
          key: this.selectedFilter || 'username',
          value: this.searchValue,
        });
      }

      this.selectedUser = null;
      this.addOrgModal = false;
    },

    closeAddOrgModal() {
      this.addOrgModal = false;
    },

    async handleAddOrgModal(record) {
      let params = { user_id: record.id };
      let [err, data] = await AuthServices.GetUserLinkedOrgByUserId(params);
      if (err) {
        throw new err();
      }

      this.selectedUserOrgs = data;
      this.selectedOrgs = data.map((obj) => obj.organization);
      this.selectedUser = record;
      this.addOrgModal = true;
    },

    toggleModal(email) {
      if (email) {
        this.email = email;
      }
      this.visible = !this.visible;
    },

    async getData(userFilter) {
      try {
        this.loading = true;

        this.list = [];
        const [error, data] = !this.selectedRole
          ? await UserServices.allUsers(this.currentPage, userFilter)
          : await UserServices.getRoleUsers(
              this.selectedRole,
              this.currentPage,
              userFilter
            );
        if (error) {
          console.log({ error });
          return;
        }
        this.list = [...data.results];

        this.listLength = data.count;

        this.list.forEach((user) => {
          const foundRole = this.definedRoles.find(
            (role) => role.value === this.allRoles[user['role']]
          );
          if (foundRole) {
            user.role = foundRole.text;
          } else {
            user.role = 'Unknown Role';
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async deleteData(userEmail) {
      const [error, data] = await UserServices.deleteUser(userEmail);
      if (error) {
        console.log({ error });
        return;
      }
      this.updateList();
    },

    async getUsers(role) {
      return await httpClient.get(
        `account/users_list?role__name=${role}`,
        false
      );
    },

    fetchUsers() {
      return new Promise(async (resolve, reject) => {
        try {
          const reqRoles = [
            roles.org_user,
            roles.analytics_user,
            roles.workstation_user,
          ].join(',');

          let users = await this.getUsers(reqRoles);
          users = users.sort((a, b) => a.id - b.id);
          return resolve(users);
        } catch (error) {
          console.log(error);
          reject();
        }
      });
    },

    async fetchRoles() {
      return new Promise(async (resolve, reject) => {
        try {
          const [error, data] = await UserServices.allRoles();
          if (error) {
            console.log({ error });
            return;
          }
          this.roleList = data;

          this.allRoles = data.reduce((res, el) => {
            res[el.id] = el.name;
            return res;
          }, {});

          return resolve(data);
        } catch (e) {
          console.log(e);
          reject();
        }
      });
    },

    handleShowForm() {
      this.showForm = !this.showForm;
    },

    async addUser(payload) {
      this.isLoading = true;
      const [error, data] = await AuthServices.AddOrgAdmin(payload);
      if (error) {
        this.isLoading = false;
        this.toast.error('User already exists');
        return;
      }
      this.isLoading = false;
      this.toast.success(data.response);
      this.handleShowForm();
    },
  },
};
</script>

<style scoped>
.actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 6px;
}

.error-msg {
  color: red;
}

.error-box {
  width: 140px;
  margin-left: 20px;
}

.custom-switch {
  background-color: green;
}
</style>
